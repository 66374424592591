@use '../settings';

header.main-header {
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: settings.$space_sm;
  width: 100%;
  position: sticky;
  z-index: 1;
  background: #fff;
  top: 0;
  left: 0;
  button > i {
    margin-right: settings.$space_xs;
  }
  button {
    font-size: 1em;
  }
}

.logo {
  max-width: 275px;
}

header.main-header > .banner {
  background: settings.$brown;
  padding: settings.$space_xs 0;
  text-align: center;
  color: #fff;
  font-size: 0.7em;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.search-form {
  margin: 10px 0 0 0;
  background: #fff;
  border-radius: 25px;
  display: inline-flex;
  width: 100%;
  height: 40px;
  align-items: center;
  padding: 4.5px 12px;
  border: 1px solid #d8d1cf;
}

.search-form > button {
  display: grid;
}

.search-form > button > img {
  width: 22px;
}

.search-form > input[type='text'] {
  background: none;
  transform: none;
  margin: 0 0 0 10px;
  padding: 0 0 0 0;
  transition: none;
  font-size: 16px;
  font-weight: 300;
  border: none;
  outline: none;
  font-family: 'quasimoda', helvetica, sans-serif;
}
[aria-hidden='true'] {
  display: none;
}
.search-autocomplete {
  width: 100vw;
  max-width: 799px;
  position: absolute;
  left: 0;
  top: 136px;
  z-index: 2;
  background: #fff;
  box-shadow: 0px 10px 20px -10px #00000070;
  overflow-y: auto;
  max-height: 67.5vh;
}

.search-autocomplete_header {
  background: #f1eae8;
  padding: 10px;
  width: 100%;
  font-size: 1.05em;
  line-height: 1;
  display: flex;
  align-items: center;
}

@media (min-width: 800px) {
  .search-form {
    display: none;
  }
}
.search-autocomplete a {
  color: settings.$black;
  text-decoration: none;
}
.search-auto-complete-results {
  grid-template-columns: 1fr 1fr 1fr;
  padding-right: 0;
  grid-gap: 10px;
}
.padding-lr-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.padding-10 {
  padding: 10px;
}
.search-autocomplete_results > .search-product:not(:first-child) {
  border-top: 1px solid #f5f8f8;
  padding-top: 10px;
}
.search-product .search-product_image {
  max-width: 65px;
  margin-right: 10px;
}
button {
  cursor: pointer;
}
.header-icon-mobile {
  width: 26px;
  svg {
    display: block;
  }
}
/* fixes hegiht diffs for header on chrome vs safari */
.menu-toggle-btn,
.header-cart-btn {
  margin: 0;
}
.menu-toggle-btn[aria-expanded='false'] > .menu-close-icon,
.menu-toggle-btn[aria-expanded='true'] > .menu-open-icon {
  display: none;
}

.header-cart-btn {
  position: relative;
}

.header-cart-count {
  position: absolute;
  bottom: 2px;
  font-size: 13.5px;
  color: #2e2d2f;
  font-weight: 600;
  font-style: normal;
}

@media (max-width: 799px) {
  .header-cart-count {
    bottom: 0.005em;
  }
}
.header-right,
.header-left {
  width: 250px;
}
.header-right {
  justify-content: flex-end;
}
.header-right button:not(:first-child),
.header-right a:not(:first-child) {
  margin-left: settings.$space_lg;
}
@media (min-width: 800px) {
  .mobile {
    display: none;
  }
  .logo {
    max-width: 260px;
  }
  .banner {
    padding: settings.$space_sm 0;
  }
}
@media (max-width: 799px) {
  .desktop {
    display: none;
  }
}
.main-nav {
  justify-content: space-evenly;
  max-width: 1000px;
  margin: 15px auto;
  font-size: 18px;
}
.main-nav > a[aria-current='page'] {
  font-weight: 600;
}
.logout-link > a {
  color: settings.$teal;
}
