@use '../settings';

@each $key, $val in settings.$space-list {
  .margin-tb-#{$key} {
    margin-top: $val;
    margin-bottom: $val;
  }

  .margin-t-#{$key} {
    margin-top: $val;
  }

  .margin-b-#{$key} {
    margin-bottom: $val;
  }

  .margin-l-#{$key} {
    margin-left: $val;
  }

  .margin-r-#{$key} {
    margin-right: $val;
  }

  .margin-lr-#{$key} {
    margin-left: $val;
    margin-right: $val;
  }
}
