.flex {
  display: flex;

  ._wrap-nowrap {
    flex-wrap: nowrap;
  }

  ._wrap {
    flex-wrap: wrap;
  }

  ._wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  &._align-flex-start {
    align-items: flex-start;
  }

  &._align-center {
    align-items: center;
  }

  &._align-flex-end {
    align-items: flex-end;
  }

  &._flex-1 {
    flex: 1;
  }

  &._flex-auto {
    flex: 1 1 auto;
  }

  &._jc-end {
    justify-content: flex-end;
  }

  &._jc-center {
    justify-content: center;
  }

  &._jc-space-between {
    justify-content: space-between;
  }

  &._jc-space-around {
    justify-content: space-around;
  }

  &._jc-space-evenly {
    justify-content: space-evenly;
  }
}

.flex-grow {
  flex-grow: 1;
}
