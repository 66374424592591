@use '../settings';

@each $key, $val in settings.$space-list {
  .padding-tb-#{$key} {
    padding-top: $val;
    padding-bottom: $val;
  }

  .padding-t-#{$key} {
    padding-top: $val;
  }

  .padding-b-#{$key} {
    padding-bottom: $val;
  }

  .padding-l-#{$key} {
    padding-left: $val;
  }

  .padding-r-#{$key} {
    padding-right: $val;
  }

  .padding-lr-#{$key} {
    padding-left: $val;
    padding-right: $val;
  }
}
