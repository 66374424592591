@use '../settings';

.image-fig {
  position: relative;
  figcaption {
    color: #fff;
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
  }

  figcaption > .subtitle {
    font-size: 0.65em;
    font-weight: 400;
  }
}

@media (max-width: settings.$mobile) {
  .image-fig figcaption {
    bottom: 0;
  }
}

// this migh tbe a common pattern
// and can go in modifiers for each button
.collection-grid .btn {
  max-width: 500px;
}

@media (min-width: 500px) {
  .collection-grid .btn {
    max-width: 300px;
    margin: settings.$space_lg auto 0 auto;
  }
}

@media (max-width: 799px) {
  .collection-image-text {
    --aspect-ratio: 535/257;
  }
}

.collection-image-text > figcaption {
  position: absolute;
  bottom: 45%;
  width: 100%;
  height: fit-content;
  text-align: center;
  color: #fff;
  text-shadow: 0 2px 4px rgba(21, 22, 22, 0.30);
  text-transform: uppercase;
  letter-spacing: 2px;
}
