@use '../settings';

*,
::before,
::after {
  box-sizing: border-box;
  font-family: inherit;
}

html {
  font-family: settings.$mainFont;
  line-height: 1;
  font-size: 62.5%; //this makes html font size 10px
}

body {
  font-size: 1.8rem;
  // font-weight: 300;
  margin: 0;
  width: 100%;
}

a {
  text-decoration: none;
  color: settings.$black;
}

body *:focus {
  outline: none;
}

body.accessible *:focus {
  outline: auto;
}

strong {
  font-weight: 500;
}

p {
  line-height: 1.5;
  margin: settings.$space_md 0;

  a {
    text-decoration: underline;
    color: settings.$teal;
  }
}

img,
.w-100 {
  width: 100%;
  height: auto;
}

.container {
  padding: 0 10px;
  max-width: 1500px;
  margin-right: auto;
  margin-left: auto;
}
