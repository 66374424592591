@use '../../../../shared-src/sass/modules/settings';
@use '../../../../shared-src/sass/modules/components/image-figcaption';

.home-banner {
  margin-top: 0;
}

.home-banner_wrap {
  --aspect-ratio: 2026 / 750;
}

@media (max-width: settings.$mobile) {
  .home-banner_wrap {
    --aspect-ratio: 375 / 425;
  }
}

.text-center {
  text-align: center;
}
