//headings.scss file for headings
@use '../settings';

h1 {
  font-size: 3.5rem;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: settings.$space_lg;
  line-height: 1;
}
h2 {
  font-size: 2.8rem;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: settings.$space_lg;
  line-height: 1;
}
h3 {
  font-size: 2.4rem;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: settings.$space_lg;
  line-height: 1;
}
h4 {
  font-size: 2rem;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: settings.$space_md;
  line-height: 1;
}
h5 {
  font-size: 1.75rem;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: settings.$space_md;
  line-height: 1;
}
h6 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: settings.$space_md;
  line-height: 1;
}
