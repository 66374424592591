@use '../settings';

form select,
form input::placeholder,
form textarea::placeholder {
  color: #777;
  opacity: 1;
}
//Microsoft Edge
::-ms-input-placeholder {
  color: #777;
}

input,
textarea,
form select {
  font-size: 1em;
  width: 100%;
}

form {
  select,
  textarea,
  input[type='text']:not(.js_search-input),
  input[type='password'],
  input[type='email'],
  input[type='tel'] {
    background: none;
    height: 50px;
    border-radius: 0px;
    padding: settings.$space_sm;
    border: 1px solid #d3d3d3;
  }

  textarea {
    height: 150px;
  }
}

select,
form select {
  background: url(//cdn.shopify.com/s/files/1/0365/7037/t/13/assets/icon-arrow-down.svg) no-repeat 98% 50%;
  -webkit-appearance: none;
}

@media (max-width: settings.$mobile) {
  input,
  form textarea,
  form select {
    font-size: 0.9em;
  }
}
