// img {
//   width: 100%;
//   height: 100%;
// }
// adds a background placeholder
.lazyloading {
  background: #f8f8f6;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
// fixing not showing broken image in between lazyloading
img:not([src]),
img:not([src]).blur {
  visibility: hidden;
  filter: none;
  position: relative;
}
// this shows placeholder color of not yet loaded image
img:not([src])::before {
  content: '';
  display: grid;
  background: #f8f8f6;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  visibility: visible;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
picture > img:not([src]),
picture > img:not([src]).blur {
  visibility: visible;
}
// adds blur effect for lazylaoded images
//wasn't working when img getting stuck wiht lazyloading class
//neeed to do in js
// img.blur,
// picture > img:not([src]).blur {
//   filter: blur(4px);
//   transition: filter 0.75s linear;
// }
// img.blur.lazyloaded,
// picture > img:not([src]).blur.lazyloaded {
//   filter: blur(0);
// }
[style*='--aspect-ratio'] > :first-child,
[data-aspect-ratio] > :first-child {
  width: 100%;
}
[style*='--aspect-ratio'] > img,
[style*='--aspect-ratio'] > picture,
[data-aspect-ratio] > img,
[data-aspect-ratio] > picture {
  height: auto;
}
@supports (--custom: property) {
  [style*='--aspect-ratio'],
  [data-aspect-ratio] {
    position: relative;
  }
  [style*='--aspect-ratio']::before,
  [data-aspect-ratio]::before {
    content: '';
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
    background: #f8f8f6;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  [style*='--aspect-ratio'] > :first-child,
  [data-aspect-ratio] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}
