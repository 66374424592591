//===============
// Settings
//===============

//fonts
$mainFont: 'Quasimoda', sans-serif;

//colors
$black: #2a2b2c;
$teal: #7b9187; //old teal #5fbcc9
$brown: #b87f6b;

//colors gradients

$gr_purple: linear-gradient(to right, #8f44a0, #6d3c84);
$gr_orange: linear-gradient(to right, #dd8c69, #c97c6b);
$gr_green: linear-gradient(to right, #c1ccc5, #c1c6c3);
$gr_earth: linear-gradient(to right, #f9f6f5, #f7e8df);
$gr_rowblock: linear-gradient(to right, #dcc8bf, #efe4de);

//spacing
$space_base: 8px;
$space_xxs: $space_base * 0.25; //2px
$space_xs: $space_base * 0.75; //6px
$space_sm: $space_base * 1.25; //10px
$space_md: $space_base * 2; //16px
$space_lg: $space_base * 3; //24px
$space_xl: $space_base * 4; //32px
$space_xxl: $space_base * 8; //64px

$space-list: (
  0: 0,
  xxs: $space_xxs,
  xs: $space_xs,
  sm: $space_sm,
  md: $space_md,
  lg: $space_lg,
  xl: $space_xl,
  xxl: $space_xxl
);

//viewports
$mobile: 799px;
$mobile-xs: 0;
$mobile-sm: 375px; //iphone x, iphone SE2
$mobile-md: 414px; //iphone Xs MAX
$mobile-lg: 550px;
$tablet: 768px;
$desktop: 800px;
$desktop-sm-width: 1024px;
$desktop-md-width: 1280px;
$desktop-lg-width: 1500px;
